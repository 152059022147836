import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html'
})

export class ProgressBarComponent implements OnInit {
  progressTitle = "You're just a few clicks away from getting covered";
  progress = 0;

  constructor(public dataService: DataService) {}

  ngOnInit() {
    this.dataService.pr.subscribe(progress => {
      if (progress) {
        this.progress = this.dataService.getProgress();
        this.setProgress(progress);
      }
    });
  }
  
  private setProgress(progress) {
    switch (progress) {
      case 20:
        this.progressTitle = "You're just a few clicks away from getting covered";
        break;
      case 30:
        this.progressTitle = "That's the first hurdle sorted, not far to go";
        break;
      case 40:
        this.progressTitle = "Your policy is only minutes away!";
        break;
      case 50:
        this.progressTitle = "You're just a few clicks away from cover";
        break;
      case 60:
        this.progressTitle = "You're just a few clicks away from cover";
        break;
      case 70:
        this.progressTitle = "Last few steps now!";
        break;
      case 80:
        this.progressTitle = "The legal bits";
        break;
      case 90:
        this.progressTitle = "Awesome! It's finishing time";
        break;
      case 100:
        this.progressTitle = "Finished!";
        break;
    }
  }
}
