<div class="bg-soft-lilac">
  <div class="container py-2 d-flex justify-content-end">
    <a class="text-primary-navy h4 mb-0" href="{{portalUrl}}/#/pages/login">Login</a>
  </div>
</div>

<div class="bg-lilac-pale" style="height: 90px;">
  <div class="container">
    <img alt="Logo" height="150" style="height: 160px; margin-top: -35px;" src="assets/images/logos/navy.png" />
  </div>
</div>
