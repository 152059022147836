import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

// Declare GTM dataLayer array.
declare global {
  interface Window { dataLayer: any[]; }
}

@Injectable({
  providedIn: 'root'
})

export class LoaderService {
  toasts: any[] = [];

  public isLoading = new BehaviorSubject(false);

  constructor(private apiService: ApiService, private router: Router) {}

  showToast(textOrTpl: string | TemplateRef<any>, tclass, duration = 7000) {
    const options = {
      classname: 'bg-' + (tclass || 'danger') + ' text-white',
      delay: duration,
      autohide: true,
    }

    this.toasts.push({ textOrTpl, ...options });
  }

  removeToast(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  getAge(dob, inYear) {
    if (inYear) {
      return moment().diff(dob, 'years');
    } else {
      return moment().diff(dob, 'days');
    }
  }

  pushGTM(plan, event, price) {
    window.dataLayer.push({
      event: event,
      productName: plan + ' Membership',
      productBrand: 'Harry Insurance',
      productSKU: 'VETS',
      productCategory: 'One Club',
      productPrice: price,
      quantity: 1,
      basketValue: '',
      revenue: '',
      currency: 'GBP',
    });
  }

  pushGTMData(data) {
    window.dataLayer.push(data);
  }

  isShowMaintenance(isSutPage) {
    this.apiService.getMaintenanceStatus().subscribe(
      (res: any) => {
        if (res.success && res.status) {
          this.router.navigate(['pages/maintenance']);
          return true;
        }

        if (isSutPage) {
          this.router.navigate(['/pages/membership']);
        }

        return false;
      });
  }
}
