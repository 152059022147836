<header>
  <app-navigation class="w-100"></app-navigation>
</header>

<main>
  <app-progress-bar></app-progress-bar>
  <router-outlet></router-outlet>
</main>

<footer class="bg-white-shoulders py-4">
  <div class="container">
    <div class="row no-gutters">
      <div class="col-md-4 text-center">
        <div id="badge-230" class="mr-4 mb-4" style="max-width: 230px;"></div>
      </div>
      <div class="col-md-8">
        Call us on <a href="tel:01274 711011">01274 711011</a>
        <br /><br />
        It is important that you respond accurately, if you don't it may affect the amount that you pay and the claims
        that we pay. If incorrect responses have been provided we may reduce or not make a claim payment or cancel
        your policy and we may not refund your premiums. Your responses must be correct for each person, animal or
        vehicle you want to insure.
        <br /><br />
        *One Club Membership shopping vouchers for HarryHall.com are subject to terms and availability and will be
        delivered in a confirmation email to the email address provided. Full terms for the voucher will be provided
        in the email.
        <br /><br />
        <a href="https://harryhall.com/terms-and-conditions" target="_blank">Terms & Conditions</a>
        &nbsp;&nbsp;&nbsp;
        <a href="https://support.harryhall.com/portal/kb/articles/privacy-policy-21-11-2019" target="_blank">Privacy Policy</a>
        &nbsp;&nbsp;&nbsp;
        <a href="https://harryhall.com/help-contact-us" target="_blank">Contact Us</a>
        <br /><br />
        &copy; Copyright Harry Hall International Limited.
        <br /><br />
        Equario Insurance (Guernsey) Limited, is registered in Guernsey under Registration Number 69867 and is
        regulated by the Guernsey Financial Services Commission. Equario Insurance (Guernsey) Limited’s registered
        office is Level 5, Mill Court, La Charroterie, St Peter Port, Guernsey GY1 1EJ.
        <br /><br />
        <span>Harry Hall International Limited is authorised and regulated by the Financial Conduct Authority (FCA) our firm reference number is 968047. You may check this on the Financial Services Register by <a href="https://register.fca.org.uk/s/">visiting the FCA website click here</a></span>
      </div>
    </div>
  </div>
</footer>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
