import { Component, OnInit } from '@angular/core';

declare const reviewsBadgeModern: any;

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})

export class FullComponent implements OnInit {
  ngOnInit() {
    reviewsBadgeModern('badge-230', {
      store: 'harry-hall',
      primaryClr: '#202440',
      starsClr: '#0076a5'
    });
  }
}
