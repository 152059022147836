import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})

export class NavigationComponent {
  portalUrl: string;

  constructor() {
    this.portalUrl = environment.link;
  }
}
