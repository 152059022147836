import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';

import { ToastsContainer } from './component/toast/toast-container';

import { ProgressBarComponent } from './shared/progress-bar/progress-bar.component';

import { IMaskModule } from 'angular-imask';

// Services
import { NgbDateCustomParserFormatter } from './services/date-format';
import { ApiService } from './services/api.service';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptorService } from './services/loader-interceptor.service';
import { LoaderComponent } from './shared/loader/loader.component';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    ProgressBarComponent,
    NavigationComponent,
    ToastsContainer,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    IMaskModule,
    RouterModule.forRoot(Approutes, { useHash: false, relativeLinkResolution: 'legacy', scrollPositionRestoration: 'disabled' })
  ],
  providers: [ApiService, LoaderService,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}
