import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()

export class ApiService {
  constructor(private http: HttpClient) {}

  public getMembershipCost() {
    return this.http.get(environment.api + 'get-engine-costs');
  }

  public sendQuoteSave(id) {
    return this.http.get(environment.api + 'send-quote-save-email/' + id);
  }

  // get policy by uuid
  public getQuoteByUUID(uuid) {
    return this
      .http
      .get(environment.api + 'get-quote-by-uuid/' + uuid);
  }

  // Save form 2
  public saveForm2(data) {
    return this.http.post<any>(environment.api + 'save-quote-1', data);
  }

  public saveForm3(data) {
    return this.http.post<any>(environment.api + 'set-quote-subscription-type', data);
  }

  public saveVetCover(data) {
    return this.http.post<any>(environment.api + 'set-quote-vet-fee-cover', data);
  }

  public saveTrailerCover(data) {
    return this.http.post<any>(environment.api + 'set-quote-trailer-cover', data);
  }

  public saveForm5(formData) {
    return this.http.post<any>(environment.api + 'set-quote-vet-fee-cover-type', formData);
  }

  public saveFormHorses(formData) {
    return this.http.post<any>(environment.api + 'set-quote-vet-fee-horses', formData);
  }

  public checkFormHorsesMonetaryValue(formData) {
    return this.http.post<any>(environment.api + 'check-quote-vet-fee-horses-monetary-value', formData);
  }

  public saveFormHorsesMonetaryValue(formData) {
    return this.http.post<any>(environment.api + 'set-quote-vet-fee-horses-monetary-value', formData);
  }

  public saveColicCover(data) {
    return this.http.post<any>(environment.api + 'set-quote-colic-cover', data);
  }

  public saveTackCover(data) {
    return this.http.post<any>(environment.api + 'set-quote-tack-cover', data);
  }

  public saveDisposalCover(data) {
    return this.http.post<any>(environment.api + 'set-quote-disposal-cover', data);
  }

  public saveMortalityCover(data) {
    return this.http.post<any>(environment.api + 'set-quote-mortality-cover', data);
  }

  public saveFamilyMember(data) {
    return this.http.post<any>(environment.api + 'set-quote-family-member', data);
  }

  public deleteFamilyMember(data) {
    return this.http.post<any>(environment.api + 'delete-quote-family-member', data);
  }

  public saveHorse(data) {
    return this.http.post<any>(environment.api + 'set-quote-horse', data);
  }

  public deleteHorse(data) {
    return this.http.post<any>(environment.api + 'delete-quote-horse', data);
  }

  public saveTerms(data) {
    return this.http.post<any>(environment.api + 'set-quote-terms-and-declarations', data);
  }

  public saveUser(data) {
    return this.http.post<any>(environment.api + 'create-quote-user', data);
  }

  public savePaymentType(data) {
    return this.http.post<any>(environment.api + 'save-quote-payment-type', data);
  }

  public download(content, filename, contentType) {
    if (!contentType) {
      contentType = 'application/octet-stream';
    }
    const a = document.createElement('a');
    const blob = new Blob([content], { 'type': contentType });
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
  }

  // External urls
  // Get address by postcode
  public getAddress(postcode) {
    return this
      .http
      .get('https://api.getAddress.io/find/' + postcode + '?api-key=' + environment.getAddApiKey + '&expand=true ');
  }

  // get Documents by uuid
  public getMaintenanceStatus() {
    return this.http.get(environment.api + 'maintenance/status');
  }
}
